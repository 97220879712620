<template>
  <div class="dialog-origin">
    <div class="title">更换后会采用新统计模式生成报表和报告，确定吗？</div>
    <div class="row">
      <div class="label">考试类型：</div>
      <div class="newExam">新高考</div>
    </div>
    <div class="row">
      <div class="label">统计模式：</div>
      <div class="tag">赋分统计</div>
      <div class="arrow">
        更换为
        <img src="@/assets/imgs/academic/arrow.png" alt="" />
      </div>
      <div class="tag">原始分统计</div>
    </div>
    <div class="tip">此操作不会清除统计模式设置与参数</div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.dialog-origin {
  .title {
    color: #0a1119;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 18px;
  }
  .row {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    .label {
      color: #0a1119;
      font-size: 14px;
    }
    .newExam {
      width: 80px;
      height: 32px;
      color: #ff7a00;
      font-weight: 400;
      text-align: center;
      line-height: 32px;
      font-size: 14px;
      background: #fff8f3;
      border-radius: 4px;
    }
    .tag {
      width: 106px;
      height: 32px;
      background: #eff6ff;
      border-radius: 4px;
      font-size: 14px;
      text-align: center;
      line-height: 32px;
      color: #2474ed;
    }
    .arrow {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 12px;
      margin: 0 24px;
      color: #0a1119;
      img {
        width: 68px;
        height: auto;
      }
    }
  }
  .tip {
    color: #5e6a7a;
    font-size: 14px;
  }
}
</style>
