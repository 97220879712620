<template>
  <div v-loading="loading" class="setting">
    <detailsHeader :key="headerKey" router-url="academic-statistics" />
    <div class="content">
      <div class="nav">
        <template v-if="$route.query.ruleId > 0">
          <template v-if="type == 1">
            <div
              v-if="scoreType === 1"
              class="statType"
              style="margin-bottom: 8px"
            >
              <div>以赋分统计</div>
              <div class="change" @click="changeScoreType">切换</div>
            </div>
            <div v-if="scoreType === 0" class="statType">
              <div>以原始分统计</div>
              <div class="change" @click="changeScoreType">切换</div>
            </div>
          </template>
          <template v-else-if="type == 2">
            <div class="statTypeRadio" style="margin-bottom: 8px">
              <div>以赋分统计</div>
              <div v-if="scoreType === 0" class="change" @click="chooseItem(1)">
                选择
              </div>
            </div>
            <div class="statTypeRadio">
              <div>以原始分统计</div>
              <div v-if="scoreType === 1" class="change" @click="chooseItem(0)">
                选择
              </div>
            </div>
          </template>
          <template v-else-if="type == 3">
            <div
              v-for="(item, index) in scoreTypeList"
              :key="index"
              :class="['detail', scoreType == index ? 'active' : '']"
              :style="{ marginBottom: index === 0 ? '8px' : '' }"
              @click="chooseItem(item.value, true)"
            >
              <div class="detail-msg">
                <img :src="item.icon" alt="" />
                <div class="detail-content">
                  <div class="title">
                    {{ item.name }}
                    <el-popover
                      placement="right"
                      popper-class="define-popper"
                      trigger="hover"
                    >
                      <template v-if="index === 0">
                        <tooltip-origin />
                      </template>
                      <template v-if="index === 1">
                        <tooltip-newExam />
                      </template>
                      <span slot="reference" class="tooltip-icon">
                        <i class="el-icon-question"></i>
                      </span>
                    </el-popover>
                  </div>
                  <div class="tags">
                    <div v-for="(it, ind) in item.tag" :key="ind" class="tag">
                      {{ it }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="desc">
                <div v-for="(it, ind) in item.content" :key="ind">{{ it }}</div>
              </div>
            </div>
          </template>
          <!-- <div class="more" @click="extend">
            <img
              v-if="type == 1 || type == 2"
              src="@/assets/imgs/academic/down.png"
              alt=""
            />
            <img v-if="type == 3" src="@/assets/imgs/academic/up.png" alt="" />
          </div> -->
        </template>
        <el-menu
          :default-active="defaultActive"
          :default-openeds="['/academic/statistics/setting']"
          class="el-menu-vertical-demo"
          router
        >
          <el-submenu
            v-if="scoreType == 1"
            index="/academic/statistics/setting"
          >
            <template slot="title">
              <img
                style="margin-right: 10px"
                src="@/assets/imgs/academic/menu.png"
                alt=""
              />
              <span>赋分设置</span>
            </template>
            <el-menu-item-group>
              <el-menu-item
                :disabled="mustSubject"
                index="/academic/statistics/setting/ap"
                :route="{
                  path: '/academic/statistics/setting/ap',
                  query: { ...$route.query },
                }"
                >赋分设置</el-menu-item
              >
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="/academic/statistics/setting">
            <template slot="title">
              <img
                style="margin-right: 10px"
                src="@/assets/imgs/academic/stat.png"
                alt=""
              />
              <span>统计设置</span>
            </template>
            <el-menu-item-group>
              <el-menu-item
                index="/academic/statistics/setting/settingWay"
                :route="{
                  path: '/academic/statistics/setting/settingWay',
                  query: { ...$route.query, scoreType: scoreType },
                }"
                >统计方式</el-menu-item
              >
              <el-menu-item
                index="/academic/statistics/setting/params"
                :route="{
                  path: '/academic/statistics/setting/params',
                  query: { ...$route.query, scoreType: scoreType },
                }"
                >统计参数</el-menu-item
              >
              <el-menu-item
                v-if="$route.query.examType == 1"
                index="/academic/statistics/setting/questions"
                :route="{
                  path: '/academic/statistics/setting/questions',
                  query: { ...$route.query, scoreType: scoreType },
                }"
                >大题设置</el-menu-item
              >
            </el-menu-item-group>
          </el-submenu>
        </el-menu>
      </div>
      <div class="page-content">
        <transition name="Fade">
          <div
            v-if="showExcloudZero && excloudZero === 'change'"
            class="warning"
          >
            <i class="el-icon-warning"></i>
            <div class="box">
              <div class="warning-title">
                <span>赋分人数变动</span>
                <i class="el-icon-close" @click="showExcloudZero = false"></i>
              </div>
              <div class="warning-content">
                已修改“排除缺考与零分考生”设定，建议应用分级数据重新计算赋分结果。
              </div>
            </div>
          </div>
        </transition>
        <transition name="Fade">
          <div v-if="showHasChangeScore && hasChangeScore == 1" class="warning">
            <i class="el-icon-warning"></i>
            <div class="box">
              <div class="warning-title">
                <span>考生成绩被调整</span>
                <i
                  class="el-icon-close"
                  @click="showHasChangeScore = false"
                ></i>
              </div>
              <div class="warning-content">
                此考试曾进行过考生“<span>调整成绩</span>”操作，建议应用分级数据重新计算赋分结果。
              </div>
            </div>
          </div>
        </transition>

        <div
          v-if="
            (excloudZero == 'change' && !showExcloudZero) ||
            (hasChangeScore == 1 && !showHasChangeScore)
          "
          class="icon"
        >
          <el-tooltip class="item" effect="dark" placement="top">
            <div slot="content" class="msg-list">
              <div class="msg-item">
                <i class="el-icon-warning"></i>赋分人数变动
              </div>
              <div class="msg-item">
                <i class="el-icon-warning"></i>考生成绩被调整
              </div>
            </div>
            <i class="el-icon-warning" @click="tagChange"></i>
          </el-tooltip>
        </div>

        <div class="solid-content">
          <router-view></router-view>
        </div>
      </div>
    </div>
    <el-dialog
      title="更换统计模式"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <template v-if="scoreType == 0">
        <dialog-origin />
      </template>
      <template v-if="scoreType == 1">
        <dialog-newExam />
      </template>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="submit"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import dialogOrigin from "./components/dialog-origin.vue";
import dialogNewExam from "./components/dialog-newExam.vue";
import { getObj } from "@/core/api/exam/exam";
import detailsHeader from "@/components/detailsHeader.vue";
import { getstatmethodsetting } from "@/core/api/academic/statistics";
import {
  getstatscoretypesetting,
  setstatscoretypesetting,
} from "@/core/api/academic/newExam";
// import tooltipNewExam from "./components/tooltip-newExam.vue";
// import tooltipOrigin from "./components/tooltip-origin.vue";

export default {
  name: "Setting",
  components: {
    detailsHeader,
    dialogOrigin,
    dialogNewExam,
    // tooltipNewExam,
    // tooltipOrigin,
  },
  provide() {
    return {
      getExcloud: this.getExcloud,
      getEditGrade: this.getEditGrade,
    };
  },
  data() {
    return {
      showWarning: true,
      headerKey: Math.random(),
      examInformation: null,
      dialogVisible: false,
      showExcloudZero: false,
      showHasChangeScore: false,
      type: 1,
      value: null,
      loading: false,
      scoreType: null, // 0原始分统计 1赋分统计
      queryData: {},
      initType: false,
      excloudZero: 0, // 0 不存在 1存在
      hasChangeScore: 0, // 0没改过成绩 1 改过
      stat: {},
      scoreTypeList: [
        {
          name: "以原始分统计",
          value: 0,
          tag: ["适用于普通考试", "初中/高中"],
          content: ["全部科目均以卷面得分进行统计"],
          icon: require("@/assets/imgs/academic/02@1x.png"),
        },
        {
          name: "以赋分统计",
          value: 1,
          tag: ["适用于新高考", "高中"],
          content: [
            "“语/数/英/物/历”科目以卷面得分进行统计",
            "“化/生/政/地”科目以赋分得分进行统计并分级",
          ],

          icon: require("@/assets/imgs/academic/01@1x.png"),
        },
      ],
      btnLoading: false,
      mustSubject: false,
      defaultActive: "",
    };
  },
  watch: {
    $route: {
      deep: true,
      handler(val) {
        if (val.path === "/academic/statistics/setting/areaSetting") {
          this.defaultActive = "/academic/statistics/setting/settingWay";
        } else {
          this.defaultActive = val.path;
        }
      },
    },
  },
  created() {
    this.queryData = { ...this.$route.query };
  },
  mounted() {
    this.defaultActive = "/academic/statistics/setting/settingWay";
    this.getExamObj();
    this.getstatscoretypesettingData();
    this.getData();
  },
  methods: {
    async getData() {
      const res = await getstatmethodsetting({ id: this.$route.query.id });
      this.stat = res.data.data.stat;
      let subjectIds = res.data.data.stat.subjectIds.split(",");
      let bin = subjectIds.filter((item) =>
        ["1", "2", "3", "4", "8"].includes(item)
      );
      this.mustSubject = bin.length === subjectIds.length;
    },
    initExcloudZero() {
      this.showExcloudZero = false;
      this.excloudZero = 0;
    },
    tagChange() {
      if (this.excloudZero === "change") {
        this.showExcloudZero = true;
      }
      if (this.showHasChangeScore) {
        this.showHasChangeScore = true;
      }
    },
    changeShowExcloudZero(val) {
      this.showExcloudZero = val;
    },
    getExcloud(val) {
      this.excloudZero = val;
      if (val === "change") {
        this.showExcloudZero = true;
      } else {
        this.showExcloudZero = false;
      }
    },
    getEditGrade(val) {
      this.hasChangeScore = val;
    },
    routerTo() {
      this.$router.push({
        path: "/exam/ExamRanking",
        query: {
          status: 2,
          examId: this.$route.query.examId,
          routerName: "mark-list",
        },
      });
    },

    handleClose() {
      this.dialogVisible = false;
    },
    close() {
      this.showWarning = false;
    },
    chooseItem(value, initType = false) {
      this.initType = initType;
      this.value = value;
      this.dialogVisible = true;
    },
    async submit() {
      this.btnLoading = true;
      try {
        await setstatscoretypesetting({
          id: Number(this.$route.query.id),
          scoreType: this.value,
        });
        this.headerKey = Math.random();
        this.getstatscoretypesettingData();
        if (!this.initType) {
          this.type = 1;
        }
        this.btnLoading = false;
        this.dialogVisible = false;
      } catch {
        this.getstatscoretypesettingData();
        if (!this.initType) {
          this.type = 1;
        }
        this.btnLoading = false;
        this.dialogVisible = false;
      }
    },
    changeScoreType() {
      this.type = 2;
    },
    extend() {
      if (this.type == 1 || this.type == 2) {
        this.type = 3;
      } else {
        this.type = 1;
      }
    },
    async getstatscoretypesettingData() {
      try {
        this.loading = true;
        const res = await getstatscoretypesetting({ id: this.$route.query.id });
        this.scoreType = res.data.data;
        this.$store.commit("report/SET_SCORE_TYPE", res.data.data);
        this.$store.commit(
          "report/IS_NEW_EXAM",
          this.$route.query.ruleId > 0 && res.data.data == 1
        );
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
    async getExamObj() {
      const res = await getObj(this.$route.query.examId);
      this.examInformation = res.data.data;
    },
  },
};
</script>
<style lang="scss" scoped>
.setting {
  box-sizing: border-box;
  padding: 16px 0;
  .content {
    display: flex;
    align-items: flex-start;
    margin-top: 18px;
    background-color: #fff;
    box-sizing: border-box;
    padding: 18px;
    width: 100%;
    .nav {
      margin-right: 18px;
      min-width: 200px;
      .statType {
        box-sizing: border-box;
        padding: 12px 18px;
        background-image: url("~@/assets/imgs/academic/bg.png");
        background-size: cover;
        color: #0a1119;
        font-size: 16px;
        border-radius: 4px;
        font-weight: 600;
        width: 240px;
        .change {
          width: 50px;
          height: 24px;
          border-radius: 4px;
          border: 1px solid #2474ed;
          text-align: center;
          line-height: 24px;
          font-weight: 500;
          margin-top: 12px;
          font-size: 12px;
          color: #2474ed;
          cursor: pointer;
        }
      }
      .statTypeRadio {
        width: 240px;
        height: 60px;
        background: #f6faff;
        border-radius: 4px;
        background-image: url("~@/assets/imgs/academic/bg.png");
        background-size: cover;
        color: #0a1119;
        font-size: 16px;
        border-radius: 4px;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 0 18px;
        .change {
          width: 50px;
          height: 24px;
          border-radius: 4px;
          border: 1px solid #2474ed;
          text-align: center;
          line-height: 24px;
          font-weight: 500;
          margin-top: 12px;
          font-size: 12px;
          color: #2474ed;
          cursor: pointer;
        }
      }
      .detail {
        width: 240px;
        background: #f6faff;
        border-radius: 4px;
        background-image: url("~@/assets/imgs/academic/bg.png");
        background-size: cover;
        color: #0a1119;
        font-size: 16px;
        font-weight: 600;
        border-radius: 4px;
        box-sizing: border-box;
        cursor: pointer;
        padding: 8px;
        border: 1px solid transparent;
        .detail-msg {
          display: flex;
          align-items: center;
          width: 100%;
          margin-bottom: 8px;
          img {
            width: 44px;
            height: 48px;
            margin-right: 8px;
          }
          .detail-content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;
            height: 48px;
            .title {
              color: #0a1119;
              font-size: 16px;
              font-weight: 600;
              display: flex;
              align-items: center;
              width: 100%;
              justify-content: space-between;
              i {
                color: #2474ed;
                font-size: 14px;
              }
            }
            .tags {
              display: flex;
              align-items: center;
              .tag {
                box-sizing: border-box;
                padding: 2px;
                border-radius: 4px;
                border: 1px solid #dadbe0;
                color: #5e6a7a;
                font-size: 12px;
                margin-right: 4px;
                font-weight: 400;
                color: #5e6a7a;
                letter-spacing: 1px;
              }
              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
        .desc {
          color: #5e6a7a;
          font-size: 12px;
          letter-spacing: 1px;
          font-weight: 400;
        }
      }
      .active {
        border: 1px solid #2474ed;
      }
      .more {
        text-align: center;
        margin: 11px 0;
        cursor: pointer;
      }
    }
    .page-content {
      flex: 1;
      width: calc(100% - 258px);
      .solid-content {
        box-sizing: border-box;
        padding: 18px;
        background: #ffffff;
        border: 1px solid #ebecee;
        border-radius: 4px;
        width: 100%;
      }
      .waring {
        width: 100%;
        box-sizing: border-box;
        padding: 14px 11px;
        background-color: #fdf6ec;
        margin-bottom: 8px;
        &-title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 7px;
          color: #292626;
          .text {
            font-size: 14px;
            font-weight: 550;
            letter-spacing: 1px;
            i {
              font-size: 16px;
              color: #fe8516;
              margin-right: 9px;
            }
          }
          .close {
            i {
              font-size: 16px;
              color: #797984;
              cursor: pointer;
            }
          }
        }
        &-text {
          font-size: 14px;
          color: #887d7d;
          letter-spacing: 1px;
          padding-left: 25px;
          span {
            color: #2474ed;
          }
        }
      }
    }
  }
}
::v-deep .el-menu {
  border-right: none;
}
.warning {
  width: 100%;
  box-sizing: border-box;
  padding: 14px 11px;
  background-color: #fdf6ec;
  border-radius: 4px;
  display: flex;
  align-items: flex-start;
  margin-bottom: 18px;
  i {
    font-size: 18px;
    color: #fe8516;
    margin-right: 9px;
  }
  .box {
    width: 100%;
    font-size: 14px;
    .warning-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #292626;
      font-weight: 550;
      margin-bottom: 7px;
      i {
        font-size: 16px;
        color: #797984;
        cursor: pointer;
      }
    }
    .warning-content {
      color: #887d7d;
      span {
        color: #2474ed;
        cursor: pointer;
      }
    }
  }
}
.icon {
  cursor: pointer;
  font-size: 16px;
  color: #fe8516;
  text-align: right;
  margin-bottom: 18px;
}
.msg-list {
  .msg-item {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    i {
      font-size: 16px;
      color: #fe8516;
      margin-right: 5px;
    }
  }
}
.Fade-enter,
.Fade-leave-to {
  opacity: 0;
}

.Fade-enter-to,
.Fade-leave {
  opacity: 1;
}

.Fade-enter-active,
.Fade-leave-active {
  transition: all 0.5s;
}
</style>
<style>
.define-popper {
  background: #fff5db;
}
</style>
